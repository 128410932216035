@import '~@progress/kendo-theme-default/dist/all.css';

/*
 * Fixes for kendo widgets
 */

/* remove vertical scrollbar in kendo grid */
.kendo-grid-no-vertical-scrollbar {
    .k-grid-header {
        padding: 0 !important;
    }

    .k-grid-header-wrap {
        border-right-width: 0;
    }

    .k-grid-content {
        overflow-y: hidden;
    }
}

/* disable word wrapping in table cells of kendo grid */
.kendo-grid-cell-nowrap td[role=gridcell] {
    white-space: nowrap;
}

/* disable footer in kendo grid */
.kendo-grid-no-footer {
    .k-grid-footer {
        display: none;
    }
}

.kendo-grid-column-right {
  text-align: right !important;
}
